<template>
    <div :class="['strip_list',listShow?'strip_list_spread':'strip_list_collapse']">
      <div class="collapse" @click="toggleList">
        <div class="left">
          <i class="iconfont icon-strip_list f14"></i>
          <span class="f14">拆条列表</span>
        </div>
        <span :class="['right',listShow?'el-icon-arrow-up':'el-icon-arrow-down']"></span>
      </div>
      <div class="list" v-if="listShow">
        <div class="list_content" v-if ="stripeListAll.length" ref="listContent">
          <!-- 视频 + 图片 -->
          <div class="material_title" v-if="stripeList.video.length">
            <div>
              <span class="f14">视频</span>
              <el-badge class="badge" :value="stripeList.video.length" :max="99"/>
            </div>
            <span class="f12" style="margin-right:12px;">{{checkedVideoAllTime}}s</span>
          </div>
          <div class="strip_item" v-for="(item,i) in stripeList.video" :key="i + 'video'">
              <div class="left_img">
                <el-checkbox label="" class="check" v-model="item.check_if"></el-checkbox>
                <i class="img_list iconfont icon-shipin1" v-if="item.ip_type == 1 && item.cover ==''"></i>
                <img class="img_list" :src="item.cover" alt="" v-if="item.ip_type == 1">
                <img class="img_list" :src="item.standard_name" alt="" v-else>
                <i :class="['iconfont icon-a-yinliang1 voice',item.mute_if?'icon-jingyin icon_block':'icon-a-yinliang1']" v-if="item.ip_type == 1" @click="videoMute(item)"></i>
              </div>
            <div class="right_des">
              <el-tooltip
                placement="bottom-start"
                popper-class="strip_tooltip"
                :content="item.part_name"
                v-if="!item.resetName"
                >
                <p class="title f12" @click="goMaterialDetail(item)">{{item.part_name}}</p>
              </el-tooltip>
              <div v-else>
                <el-input
                  class="input-new-tag"
                  v-model="item.nameValue"
                  size="mini"
                  placeholder="请输入名称"
                  v-focus
                  @keyup.enter.native="nameConfirm(item)"
                >
                </el-input>
                <div class="operation">
                  <i class="iconfont icon-duihao" @click="nameConfirm(item)"></i>
                  <i class="iconfont icon-cuo" @click="nameCancel(item)"></i>
                </div>
              </div>
              <transition name="el-fade-in">
                <div class="existed_tag category_tag" v-if="item.nullName">名称不能为空！</div>
              </transition>
              <transition name="el-fade-in">
                <div class="success_tag category_tag" v-if="item.editNameSucc">名称修改成功！</div>
              </transition>

              <span class="duration f12" v-if="item.ip_type == 1">{{parseInt(item.duration)}}s</span>

              <div class="pic_time" v-if="item.ip_type == 2">
                <span class="f12 time">{{item.img_time1}}</span>
                <i class="el-icon-caret-top f12 arrow_top" @click="picAdd(item)"></i>
                <i :class="['el-icon-caret-bottom f12 arrow_bottom',item.img_time <= 1 ? 'disable_arrow' : '']" @click="picMinus(item)"></i>
              </div>
              <span class="right_show_speed" v-if="item.ip_type == 1">{{item.speed}}x</span>
              <div class="hover_operation">
                <i class="iconfont icon-shangyi" @click="seqOrder(1,item,i)" v-if="i!=0"></i>
                <i class="iconfont icon-xiayi" @click="seqOrder(-1,item,i)" v-if="i!=stripeList.video.length - 1"></i>
                <span class="speed" v-if="item.ip_type == 1">
                  <i class="iconfont icon-biansu"></i>
                  <ul>
                    <li :class="['f12',speed.choose?'active_speed':'']" v-for="(speed,j) in item.muteGroup" :key="j" @click="chooseSpeed(item,speed)">{{speed.name}}</li>
                  </ul>
                </span>
              </div>
              <i class="iconfont icon-bianji" @click="editName(item)" v-if="!item.resetName"></i>
            </div>
          </div>
          <div ref="videoLocation"></div>
          <!-- 音频 -->
          <div class="material_title" v-if="stripeList.audio.length">
            <div>
              <span class="f14">音频</span>
              <el-badge class="badge" :value="stripeList.audio.length" :max="99"/>
            </div>
            <span class="f12" style="margin-right:12px;">{{checkedAudioAllTime}}s</span>
          </div>

          <div class="strip_item" v-for="(item,i) in stripeList.audio" :key="i + 'audio'">
              <div class="left_img" style="background: #1B1B2A;">
                <el-checkbox label="" class="check" v-model="item.check_if"></el-checkbox>
                <img class="img_list" src="../assets/images/headset.png" alt="">
              </div>
            <div class="right_des">
              <el-tooltip
                placement="bottom-start"
                popper-class="strip_tooltip"
                :content="item.part_name"
                v-if="!item.resetName"
                >
                <p class="title f12" @click="goMaterialDetail(item)">{{item.part_name}}</p>
              </el-tooltip>
              <div class="edit_name" v-else>
                <transition name="el-fade-in">
                  <div class="existed_tag category_tag" v-if="item.nullName">名称不能为空！</div>
                </transition>
                <transition name="el-fade-in">
                  <div class="success_tag category_tag" v-if="item.editNameSucc">名称修改成功！</div>
                </transition>
                <el-input
                  class="input-new-tag"
                  v-model="item.nameValue"
                  size="mini"
                  placeholder="请输入名称"
                  @keyup.enter.native="nameConfirm(item)"
                  v-focus
                >
                </el-input>
                <div class="operation">
                  <i class="iconfont icon-duihao" @click="nameConfirm(item)"></i>
                  <i class="iconfont icon-cuo" @click="nameCancel(item)"></i>
                </div>
              </div>
              <span class="duration f12">{{parseInt(item.duration)}}s</span>
              <span class="right_show_speed">{{item.speed}}x</span>
              <div class="hover_operation">
                <i class="iconfont icon-shangyi" @click="seqOrder(1,item,i)" v-if="i!=0"></i>
                <i class="iconfont icon-xiayi" @click="seqOrder(-1,item,i)" v-if="i!=stripeList.audio.length - 1"></i>
                <span class="speed">
                    <i class="iconfont icon-biansu"></i>
                    <ul>
                      <li :class="['f12',speed.choose?'active_speed':'']" v-for="(speed,j) in item.muteGroup" :key="j" @click="chooseSpeed(item,speed)">{{speed.name}}</li>
                    </ul>
                </span>
              </div>
              <i class="iconfont icon-bianji" @click="editName(item)" v-if="!item.resetName"></i>
            </div>
          </div>
          <div ref="audioLocation"></div>
        </div>
        <noResult v-else iconUrl="icon-meiyousucai" iconSty="font-size:44px" textSty="font-size:12px" noResultText="拆条列表为空" style="height: calc(100% - 108px);"></noResult>

        <div class="oper_list">
          <div class="top">
            <el-checkbox v-model="checkAll" :disabled="!stripeListAll.length">全选</el-checkbox>
            <div>
              <el-button class="btn_sty mr20" @click="deleteModal" :disabled="!checkedList.length">删除</el-button>
              <el-button class="btn_sty proGressShow" @click="proGressShow" :disabled="!composeListAll.length">
                <i class="iconfont icon-hechengliebiao f12"></i>
                <el-badge class="badge" :value="composeListAll.length" :max="99"/>
              </el-button>
            </div>
          </div>
          <el-button class="btn_sty compose" @click="composeModal" :disabled="!checkedList.length">合<span style="display:inline-block;width:18px"></span> 成</el-button>
        </div>
      </div>

      <!-- 合成进度弹窗 -->
      <div class="fix_modal" v-if="progressModal">
        <p class="f12 progress_title">合成列表（ 仅显示近七天未保存的文件 ）</p>
        <div class="content">
          <div class="progress_list" v-for="(item,i) in composeListAll" :key="i">
            <span class="f12 material_name">{{item.name}}</span>
            <el-progress :percentage="item.progress" :show-text="false" v-if="item.progress_t != '100'"></el-progress>
            <i class="iconfont icon-duihao" v-else></i>
            <el-tooltip effect="dark" content="播放" placement="top">
              <span class="iconfont icon-bofang" @click="previewShow(item)" v-if="item.progress_t == '100'"></span>
            </el-tooltip>
            <el-tooltip effect="dark" content="保存" placement="top">
              <span class="iconfont icon-baocun" @click="saveToMaterial(item.id,i)" v-if="item.progress_t == '100'"></span>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除" placement="top">
              <span class="iconfont icon-shanchujilu" @click="deleteFromMaterial(item.id,i)" v-if="item.progress_t == '100'"></span>
            </el-tooltip>
          </div>
        </div>
      </div>

      <!-- 合成弹窗 -->
      <el-dialog
          title="合 成"
          :visible.sync="composeDialog"
          width="460px"
          top="30vh"
          custom-class="compose-dialog"
          :show-close="false"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          >
          <div class="warning_content">
            <div>
              <div class="input_group" v-if="!ifShowResoltion">
                <span class="label">分辨率</span>
                <el-input v-model="resolutionW" placeholder="长" type="text" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                <span style="padding:0 16px;">×</span>
                <el-input v-model="resolutionH" placeholder="宽" type="text" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
              </div>
              <div class="input_group">
                <span class="label">名称</span>
                <el-input v-model="composeName" placeholder="请输入名称" :maxlength="40"></el-input>
              </div>
              <browseRight @typeValue="getTypeValue" @departmentSeeIds="getDepartmentSeeIds" :emptydata="ifEmptyData"></browseRight>
            </div>
          </div>
          <span slot="footer" class="dialog-footer" style="padding:10px 53px 36px 118px;">
            <el-button type="primary" @click="defineCompose">确 定</el-button>
            <el-button @click="cancelCompose">取 消</el-button>
          </span>
      </el-dialog>

      <el-dialog
        :title="previewInfo.name"
        :visible.sync="previewModal"
        width="500px"
        top="30vh"
        custom-class="compose-dialog preview_dialog"
        :before-close="handlePreviewClose"
        :close-on-click-modal = "false"
        >
        <Xgplayer id="previewVideo" :config="playerConfig" @player="playInit($event)" v-show="previewInfo.ip_type == 1"/>
        <div class="audio_box" v-show="previewInfo.ip_type == 3">
          <img src="../assets/images/music_cover.gif" alt="">
          <div id="previewAudio"></div>
        </div>
      </el-dialog>
      <!-- 全部删除弹出 -->
       <el-dialog
          title="删 除"
          :visible.sync="deleteDialog"
          width="404px"
          top="30vh"
          custom-class="compose-dialog delete_list_dialog"
          :show-close="false"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          >
          <div class="warning_content">
            <i class="el-icon-warning-outline warning_icon"></i>
            <span>您确定要删除所选拆条吗？</span>
          </div>
          <span slot="footer" class="dialog-footer" style="padding:10px 72px 28px 72px">
            <el-button type="primary" @click="modifyStripeList(1)">确 定</el-button>
            <el-button @click="deleteDialog = false">取 消</el-button>
          </span>
      </el-dialog>
    </div>
</template>
<script>
import browseRight from '../component/browseRight.vue'
import Xgplayer from "xgplayer-vue";
import Music from 'xgplayer-music';
// import Player from "xgplayer";
import '../assets/.xgplayer/skin/index.js'
import noResult from '../component/noResult.vue'
export default {
  components: {
      browseRight,
      Xgplayer,
      noResult
  },
  data() {
    return {
      listShow: false,
      progressModal: false,
      composeDialog: false,
      deleteDialog: false,
      previewModal: false,
      resolutionW: '1920', //分辨率长度
      resolutionH: '1080', //分辨率宽度
      composeName: '', //合成名称
      player: null,
      musicPlayer: null,
      stripeList: {
        video: [],
        audio: []
      },
      muteGroup: [
        {name: "0.5x", value: 0.5, choose: false},
        {name: "1x", value: 1, choose: false},
        {name: "1.5x", value: 1.5, choose: false},
        {name: "2x", value: 2, choose: false},
      ],
      viewType: "1",
      departmentSeeIds:[],
      ifEmptyData: false,
      composeList: [], //合成中
      composeList1: [], //合成完成
      composeTimer: null,
      composeTimer1: null,
      previewInfo: {},
      playerConfig: {},
      stayOpenList:[
        "el-button btn_sty proGressShow el-button--default",
        "iconfont icon-hechengliebiao f12",
        "el-badge__content el-badge__content--undefined",
        "el-tooltip iconfont icon-bofang",
        "el-tooltip iconfont icon-baocun",
        "el-tooltip iconfont icon-shanchujilu",
        "el-tooltip iconfont icon-shanchujilu focusing",
        "iconfont icon-duihao",
        "fix_modal",
        "f12 progress_title",
        "content",
        "progress_list",
        "f12 material_name",
        "el-dialog__wrapper",
        "el-dialog compose-dialog preview_dialog",
        "el-dialog__header",
        "el-dialog__body",
        "el-dialog__title",
        "el-dialog__headerbtn",
        "el-dialog__close el-icon el-icon-close"
      ],
    }
  },
  directives: {
    focus: {
      inserted: function(el) {
        el.querySelector("input").focus();
      }
    }
  },
  watch: {
    "$store.state.updateStripe": {
      deep: true,
      handler: function (newValue, oldValue) {
       if (newValue.update) {
        //  console.log(newValue.update)
        this.listShow ? "" : this.listShow = true
         this.getStripeList()
        }
        if(newValue.type == 1 || newValue.type == 2){
          this.$nextTick(()=>{
            setTimeout(()=>{
              this.$refs.videoLocation.scrollIntoView({
                behavior: "smooth",
                block:    "center"
              })
            },500)
          })
        }
        if(newValue.type == 3){
          this.$nextTick(()=>{
            setTimeout(()=>{
              this.$refs.audioLocation.scrollIntoView({
                behavior: "smooth",
                block:    "end"
              })
            },500)
          })
        }
     },
    },
    composeListAll (val) {
      let flag = true
      for(let item of val) {
        item.progress_t == '0' ? flag = false :''
      }
      if(flag){
        clearInterval(this.composeTimer)
        clearInterval(this.composeTimer1)
      }
    },
    progressModal (val) {
      if(val) {
        this.composeTimer = setInterval(()=>{this.getComposeList(0)}, 1500)
        this.composeTimer1 = setInterval(()=>{this.getComposeList(1)}, 1500)
      }else {
        clearInterval(this.composeTimer)
        clearInterval(this.composeTimer1)
      }
    }
  },
  mounted() {
    this.getStripeList()
    this.getComposeList(0)
    this.getComposeList(1)
    let that = this
    document.addEventListener('click',function(e){
      let flag = true
      that.stayOpenList.forEach((ele)=>{
        if(e.target.className == ele){
          flag = false
        }
      })
      flag ? that.progressModal = false : ''
    })

  },
  computed: {
    stripeListAll() { //所有列表，包括音视频图片
      return this.stripeList.video.concat(this.stripeList.audio)
    },
    composeListAll() { //所有合成列表，包括合成中和合成完成的
      return this.composeList.concat(this.composeList1)
    },
    checkedList() { //选中的列表
      return this.stripeListAll.filter(ele=>ele.check_if)
    },
    checkedListId() { //选中的列表id
      return this.checkedList.map(ele=>ele.id)
    },
    checkedVideoTime() { //选中的视频列表时长数组
      return this.stripeList.video.map(ele=>parseInt(ele.duration))
    },
    checkedAudioTime() { //选中的视频列表时长数组
      return this.stripeList.audio.map(ele=>parseInt(ele.duration))
    },
    checkedVideoAllTime() { //选中的视频列表总时长
     let arr =  this.stripeList.video.filter(ele=>ele.check_if)
      let time = []
      if(arr.length) {
        time = arr.map(ele=>parseInt(ele.duration))
        return time.reduce((a,b)=>a+b)
      }else {
        return 0
      }
    },
    ifShowResoltion () { //判断勾选的类型是否全相同
        let arr = this.checkedList.map(ele=>ele.ip_type)
        if(arr.length) {
          let flag = true
          arr.forEach((item)=>{
            item != 3 ? flag = false :''
          })
          if(flag){
            return true
          }else{
            return false
          }
        }
    },

    checkedAudioAllTime() { //选中的音频列表总时长
    let arr =  this.stripeList.audio.filter(ele=>ele.check_if)
      let time = []
      if(arr.length) {
        time = arr.map(ele=>parseInt(ele.duration))
        return time.reduce((a,b)=>a+b)
      }else {
        return 0
      }
      // return this.checkedAudioTime.reduce((a,b)=>a+b)
    },
    checkAll: { //全选操作
      get: function () {
        return this.stripeListAll.filter(ele=>ele.check_if).length == this.stripeListAll.length && this.stripeListAll.filter(ele=>ele.check_if).length != 0
      },
      set: function (val) {
        console.log(val)
        this.stripeListAll.forEach(ele=>{
          ele.check_if = val
        })
      }
    }

  },
  methods:{
    setAudio(url) {
      let musicPlayer = new Music({
        id: 'previewAudio',
        url: [
          {
            src: url,
            name: '',
            vid: '000001',
            poster: ""
          }
        ],
        volume: 0.8,
        width: '100%',
        height: 42,
        preloadNext: true,
        switchKeepProgress: false,
        volumeShow: true,
      })
      this.musicPlayer = musicPlayer
    },
    toggleList () {
      this.listShow = !this.listShow
    },

    proGressShow () {
      this.progressModal = !this.progressModal
    },
    defineCompose () {
      // 分辨率、名称必填
      if(this.composeName.trim() == "") {
        this.$message({
          showClose: true,
          message: '请填写视频名称',
          type: 'error'
        });
        return
      }
      this.ifEmptyData = false
      this.composeVideo()
    },
    cancelCompose () {
      this.composeDialog = false
      this.ifEmptyData = false
    },
    composeModal () {
      this.resetComposeData()
      this.composeDialog = true
    },
    previewShow (item) {
      this.previewModal = true
      this.previewInfo = item
      if(item.ip_type == 1) {
        // 视频的配置
        this.playerConfig = {
            id: "previewVideo",
            videoInit: true,
            cssFullscreen: true,
            poster: "",
            fluid: true,
            download: false,
            closeVideoDblclick: true,
            url: item.url,
            playbackRate: [0.5, 0.75, 1, 1.5, 2],
            ignores: ['fullscreen', 'replay'],
            progressDot: [],
        }
      }else{
        // setTimeout(()=>{
          this.$nextTick(()=>{
            this.setAudio(item.url)
          })
        // },1000)
      }
    },
    deleteModal () {
      this.deleteDialog = true
    },
    // 全部静音/取消静音
    // checkMute () {
    //   this.modifyStripeList(2)
    // },
    videoMute (item) {
      this.modifyStripeList(2,item)
    },
    // 倍速选择
    chooseSpeed(item,speed) {
      item.muteGroup.map(ele=>ele.choose = false)
      console.log(item.muteGroup)
      item.speed = speed.value
      speed.choose = true
      this.modifyStripeList(3,speed.value,item.id)
    },
    // 初始化视频播放器
    playInit($event) {
      this.player = $event;
    },

    // 获取拆条列表接口
    getStripeList () {
      this.$http({
        url: "/vw_material/update_open_list/",
        method: "GET"
      }).then((res) => {
        res.data.result.video.length && res.data.result.video.forEach((item)=>{
          if(item.ip_type == 2) {
            item.img_time = Number(item.img_time)
            this.picTime(item)
          }else{
            item.muteGroup = [ {name: "0.5x", value: 0.5, choose: false}, {name: "0.75x", value: 0.75, choose: false}, {name: "1x", value: 1, choose: false}, {name: "1.5x", value: 1.5, choose: false}, {name: "2x", value: 2, choose: false}]
            item.muteGroup.map((speed)=>{
              item.speed == speed.value ?speed.choose = true:''
            })
          }
          item.resetName = false
          item.nameValue = ""
          item.editNameSucc = false
          item.nullName = false
        })

        res.data.result.audio.length && res.data.result.audio.forEach((item)=>{
            item.muteGroup = [ {name: "0.5x", value: 0.5, choose: false}, {name: "0.75x", value: 0.75, choose: false}, {name: "1x", value: 1, choose: false}, {name: "1.5x", value: 1.5, choose: false}, {name: "2x", value: 2, choose: false}]
            item.muteGroup.map((speed)=>{
              item.speed == speed.value ?speed.choose = true:''
            })
            item.resetName = false
            item.nameValue = ""
            item.editNameSucc = false
            item.nullName = false
        })

        this.stripeList = res.data.result
        this.$store.commit("stripeUpdate",{type: '',update: false})

      }).catch(error => console.log(error));
    },

    // 图片的时长增加减少
    picAdd (item) {
      item.img_time += 1
      this.picTime(item)
      this.modifyStripeList(5,item)
    },

    picMinus (item) {
      if(item.img_time > 1) {
        item.img_time -= 1
        this.picTime(item)
        this.modifyStripeList(5,item)
      }
    },

    picTime(item) {
      let val = item.img_time
      var hh = parseInt(val/3600);
      if(hh<10) hh = "0" + hh;
      var mm = parseInt((val-hh*3600)/60);
      if(mm<10) mm = "0" + mm;
      var ss = parseInt((val-hh*3600)%60);
      if(ss<10) ss = "0" + ss;
      var length = hh + ":" + mm + ":" + ss;
      if(val>0){
        item.img_time1 = length
      }else{
      }
    },

    // 编辑每个拆条列表名称
    editName (item) {
      item.resetName = true
      item.nameValue = item.part_name
    },
    nameConfirm (item) {
      if(item.nameValue.trim() == "") {
        item.nullName = true
        setTimeout(() => {
          item.nullName = false
        }, 3000);
        return
      }
      this.modifyStripeList(4,item)
    },
    nameCancel (item) {
      item.resetName = false
      item.nullName = false
    },

    // 拆条列表顺序调整接口
    seqOrder (val,item,index) {
      let formData = new FormData()
      formData.append("id",item.id)
      formData.append("ip_type",item.ip_type)
      formData.append("move_type",val)
      this.$http({
        url: "/vw_material/adjust_the_order/",
        method: "POST",
        data: formData
      }).then((res) => {
        // 1.判断是视频图片，还是音频
        // 2.判断是上移还是下移
        if(item.ip_type != 3) {
          this.listOrder(this.stripeList.video,val,index)
        }else{
          this.listOrder(this.stripeList.audio,val,index)
        }
      }).catch(error => console.log(error));
    },

    // 音视频等上移下移
    listOrder (arr,move,index) {
      if(move == 1) {  //上移
        if (arr.length > 1 && index !== 0) {
          var temp = arr[index - 1]; //上移：当前item与上一个交换位置
          arr[index - 1] = arr[index];
          arr[index] = temp;
          this.$forceUpdate()
        }
      }else{  //下移
        if( arr.length > 1 && index != arr.length - 1 ) {
          var temp = arr[index + 1];     // 下移：当前item与下一个交换位置
          arr[index + 1] = arr[index];
          arr[index] = temp;
          this.$forceUpdate()
        }
      }
    },

    // 修改拆条信息接口(列表所有修改都要实时传到后台)
    modifyStripeList (type,item,id) {
      let params = {}
      // type:1.删除，2.是否静音，3.调整倍速，4.修改名字，5.修改图片时长
      switch (type) {
        case 1 :
          params.id = this.stripeListAll.length == this.checkedListId.length ? '0':this.checkedListId.join(",")
          params.delete_if = 1
          break;
        case 2 :
          params.id = item.id.toString()
          params.mute_if = item.mute_if ? 0 : 1
          break;
        case 3 :
          params.id = id.toString()
          params.speed = item
          break;
        case 4 :
          params.id = item.id.toString()
          params.name = item.nameValue.replace(/(^\s*)|(\s*$)/g, "")
          break;
        case 5 :
          params.id = item.id.toString()
          params.img_time = item.img_time
          break;
      }

      this.$http({
        url: "/vw_material/update_open_list/",
        method: "PUT",
        data: JSON.stringify(params)
      }).then((res) => {
        if(type == 1){
          this.deleteDialog = false
          this.getStripeList()
        }else if(type == 2){
          item.mute_if = !item.mute_if
        }else if(type == 4){
          item.editNameSucc = true
          setTimeout(() => {
            item.editNameSucc = false
          }, 3000);
          item.part_name =item.nameValue.replace(/(^\s*)|(\s*$)/g, "")
          item.resetName = false
        }
      }).catch(error => console.log(error));
    },

    // 合成视频/音频接口
    composeVideo (){
      let formData = new FormData()
      formData.append("obj_ids",this.checkedListId.join(",")) //拆条列表id，以逗号隔开
      formData.append("resolution",this.resolutionW + "*" + this.resolutionH) //分辨率 a*b
      formData.append("new_name",this.composeName) //视频名字
      formData.append("view_type", this.viewType) //可见类型，1-公开，2-自己可见，3-部分可见，4-不给谁看
      formData.append("user_ids", this.departmentSeeIds) //部分可见或者不可见人的id，以逗号分隔 (非必填)

      this.$http({
        url: "/vw_material/create_new_video/",
        method: "POST",
        data: formData
      }).then((res) => {
        this.composeDialog = false
        this.getComposeList(0)
        this.getComposeList(1)
      }).catch(error => console.log(error));
    },


    getTypeValue (val) {
      this.viewType = val
    },
    getDepartmentSeeIds (val) {
      this.departmentSeeIds = val
    },
    resetComposeData () {
      this.resolutionW = '1920'
      this.resolutionH = '1080'
      this.composeName = ''
      this.viewType = '1'
      this.departmentSeeIds =[]
      this.ifEmptyData = true

    },

    // 合成结果列表接口
    getComposeList (status) {
      let params = {
        status: status  //status,0:合成中，1:合成完成
      }
      this.$http({
        url: "/vw_material/get_synthetic_list/",
        method: "GET",
        params: params
      }).then((res) => {
        status == 0 ? this.composeList = res.data.result : this.composeList1 = res.data.result
      }).catch(error => console.log(error));
    },

    //type:1.保存至素材库,2.删除素材
    editComposeList (type,id) {
      let params = {
        syn_id: id
      }
      type == 1 ? params.synthetic_if = 1 : params.cancel_if = 1
      this.$http({
        url: "/vw_material/get_synthetic_list/",
        method: "PUT",
        data: JSON.stringify(params)
      }).then((res) => {
        type == 1 ? this.$message({message: '成功保存至素材库！', type: 'success'}) : ""
      }).catch(error => console.log(error));
    },

    saveToMaterial (id,i) {
      this.editComposeList(1,id)
      this.composeListAll.splice(i,1)
      this.$forceUpdate()
    },

    deleteFromMaterial (id,i) {
      this.composeListAll.splice(i,1)
      this.composeListAll.length == 0 ? this.progressModal = false : ''
      this.$forceUpdate()
      this.editComposeList(2,id)
    },
    handlePreviewClose (done) {
      this.progressModal = true
      if(this.previewInfo.ip_type == 3) {
        this.musicPlayer.pause()
          // this.musicPlayer.destroy(true)
      }else {
        this.player.destroy(true)
      }
      done()
    },
    goMaterialDetail (item) {
      sessionStorage.removeItem('time')
      let type = null
      switch (item.ip_type) {
        case 1:
          type = 'video'
          break;
        case 2:
          type = 'img'
          break;
        case 3:
          type = 'audio'
          break;
      }
      this.$router.push({
        path: 'material_detail',
        query: { ip_id: item.ip_id, materialType: type }
      })
      const time = {
        start: item.start_time,
        end: item.end_time,
      }
      sessionStorage.setItem('time',JSON.stringify(time))
      this.$store.commit("goMaterialDetail", true);
    }
  },
  beforeDestroy () {
    sessionStorage.removeItem('time')
  },
  destroyed () {
    clearInterval(this.composeTimer)
    clearInterval(this.composeTimer1)
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/mixin.scss';
.strip_list{
    position: absolute;
    left: 0;
    width: 254px;
    .collapse{
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding: 0 38px 0 34px;
      background-color: $asidebg;
      box-sizing: border-box;
      color: $white9;
      cursor: pointer;
      @include flex-align-justify(center,space-between);
      .icon-strip_list{
        margin-right: 12px;
      }
      &:hover{
        background-color: #252535;
      }
    }

    .list{
      width: 100%;
      box-sizing: border-box;
      height: calc(100vh - 40px - 51px);
      background-color: $asidebg;
      .list_content{
        height: calc(100% - 108px);
        padding: 0 5px 0 8px;
        @include scrollbar(3px,5px);
        .material_title{
          margin: 12px 0;
          height: 40px;
          @include flex-align-justify(center,space-between);
          border-bottom: 2px solid #2b2a39;
          .badge{
            width: 18px;
            height: 18px;
            line-height: 18px;
            display: inline-block;
            margin-left: 9px;
          }
        }
        .strip_item{
          // background-color: rgb(226, 91, 91);
          padding: 12px 13px 12px 7px;
          display: flex;
          color: $white8;
          border: 1px solid transparent;
          @include border-radius(2px);
          &:hover{
            border: 1px solid #2A9CFF;
            .right_show_speed{
              display: none;
            }
          }
          .iconfont{
            font-size: 12px;
            @include hover-style;
          }

          .left_img{
            @include common-item(69px,44px);
            position: relative;
            .voice{
              display: none;
              position: absolute;
              bottom: 0;
              right: 0;
              padding: 2px;
              background-color: rgba(0,0,0,0.5);
            }
            &:hover{
              .voice{
                display: block;
              }
            }
            .icon-jingyin{
              color: #894141;
            }
            .icon_block{
              display: block;
            }
            .check{
              position: absolute;
              bottom: 0;
              left: 0;
              /deep/.el-checkbox__inner{
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid $black999 !important;
                background-color: #fff !important;
              }
              /deep/.el-checkbox__input.is-checked .el-checkbox__inner{
                border: 2px solid #409EFF !important;
                &::after{
                  border: 2px solid #409EFF;
                  border-left: none;
                  border-top: none;
                }
              }
            }
          }
          .right_des{
            margin-left: 9px;
            position: relative;
            width: 100%;
            .title{
              @include text-ellipsis(159px);
              // display: block;
              margin-bottom: 8px;
              cursor: pointer;
              @include hover-style;
            }
            .hover_operation{
              display: none;
              // display: block;
              position: absolute;
              right: 0;
              bottom: 0;
              .iconfont{
                &:not(:first-child){
                  margin-left: 8px;
                }
              }
            }
            .duration{
              display: block;
            }
            .speed{
                position: relative;
                margin-left: 8px;
                padding-top: 6px;
                line-height: 12px;
                display: inline-block;
                ul{
                  display: none;
                  position: absolute;
                  bottom: 18px;
                  right: -13px;
                  background-color: #2D2D3D;
                  white-space: nowrap;
                  padding: 6px 8px;
                  border-radius: 2px;
                  z-index: 99;
                  li{
                    @include hover-style;
                    &:not(:last-child){
                      margin-bottom: 7px;
                    }
                  }
                }
                &:hover{
                  ul{
                    display: block;
                  }
                }
                .active_speed{
                  color: $highLightBlue;
                }
              }

            .icon-bianji{
              position: absolute;
              top: 4px;
              right: 0;
            }
            .pic_time{
              background-color: #282736;
              width: 70px;
              height: 20px;
              line-height: 20px;
              position: relative;
              .time{
                padding-left: 4px;
                font-size: 12px;
              }
              i{
                cursor: pointer;
                position: absolute;
                right: 3px;
              }
              .arrow_top{
                top: 0px;
              }
              .arrow_bottom{
                bottom: 0px;
              }
              .disable_arrow{
                color: $white4;
                cursor: auto;
              }
            }
            .right_show_speed{
              font-size: 12px;
              color: $highLightBlue;
              position: absolute;
              right: 0;
              bottom: 0;
            }
            // 修改name样式
            .input-new-tag{
              width: 130px;
              vertical-align: bottom;
              border-radius: 6px;
              /deep/.el-input__inner{
                border: 1px solid #5E5F72;
                background-color: #0F0F1E;
                color: #fff;
                height: 24px;
                line-height: 24px;
                padding: 0 10px;
              }
            }
            .category_tag{
              font-size: 12px;
              line-height: 16px;
              border-radius: 2px;
              padding: 5px 9px;
              position: absolute;
              box-sizing: border-box;
              left: 0;
              top: -36px;
              white-space: nowrap;
            }
            .existed_tag{
              background-color: #fde2e2;
              color: #f56c6c;
            }
            .success_tag{
              background-color: #2A9CFF;
              color: #fff;
            }
            .operation{
              display: inline-block;
              font-size: 16px;
              line-height: 24px;
              .iconfont{
                cursor: pointer;
                font-size: 16px;
                margin-left: 3px;
              }
              .icon-duihao{
                color: #69BA63;
                &:hover{
                  color: #5FED55
                }
              }
              .icon-cuo{
                color: #894141;
                &:hover{
                  color: #D54B4B;
                }
              }
            }

          }
          &:hover{
            .hover_operation{
              display: block;
            }
          }
        }
      }
      .oper_list{
        height: 107px;
        padding:14px 15px 0 15px;
        box-sizing: border-box;
        // background-color: hotpink;
        border-top: 1px solid #252535;
        .top{
          .el-checkbox{
            width: auto;
          }
          @include flex-align-justify(center,space-between);
        }
        .btn_sty{
          color: $white8;
          background: #2b2a39;
          border: 1px solid #5e5f72;
          border-radius: 6px;
          padding: 5px 9px;
          font-size: 12px;
          position: relative;
          margin: 0;
          overflow: visible;
          @include hover-style;
          .badge{
            position: absolute;
            top: -10px;
            right: -15px;
          }
        }
        .compose{
          margin-top: 12px;
          text-align: center;
          // letter-spacing: 20px;
          padding: 7px 0;
          width: 100%;
        }
        .el-button{
          /deep/span{
            font-size: 12px;
          }
        }
        .el-checkbox.is-disabled{
          @include disabled;
        }
        .el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover{
          @include disabled;
        }
      }

    }
    .el-badge{
      /deep/.el-badge__content{
        border: none;
        background-color: #FF9300;
      }
    }
    .fix_modal{
      width: 440px;
      height: 269px;
      position: absolute;
      bottom: 30px;
      left: 286px;
      background-color: $modalLight;
      box-sizing: border-box;
      border-radius: 2px;
      padding: 13px 20px 20px;
      z-index: 999999;
      .content{
        margin-top: 12px;
        height: 207px;
        background-color: $modalDark;
        overflow-y: auto;
        @include scrollbar(3px,3px);
        .progress_list{
          @include flex-align-justify(center,space-between);
          padding: 13px 20px 13px 10px;
          border-bottom: 1px solid #3E3D4C;
          .material_name{
            width: 45%;
            @include text-ellipsis(45%);
          }
          .el-progress{
            width: 26%;
          }
          /deep/.el-progress-bar {
            .el-progress-bar__outer {
              background: rgba(42, 156, 255, 0.6);
            }
            .el-progress-bar__inner {
              background: $highLightBlue;
            }
          }
          .iconfont{
            cursor: pointer;
            font-size: 12px;
             @include hover-style;
          }
          .icon-duihao{
            color: #5FED55;
            cursor: default !important;
            &:hover{
              color: #5FED55;
            }
          }

        }
      }
    }

}
.strip_list_spread{
  top: 49px;
  width: 286px;
  // z-index: 12;
  .collapse{
    padding: 0 20px 0 15px;
    background-color: #252535;
  }
}
.strip_list_collapse{
  bottom: 32px;
}
</style>
<style lang="scss">
@import '~@/assets/css/mixin.scss';
.strip_tooltip.el-tooltip__popper{
  max-width: 250px;
}
.el-dialog.compose-dialog{
  background-color: #292939;
  border-radius: 15px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
  padding: 8px 21px 21px;
  .el-dialog__header{
    text-align: center;
    padding: 0;
    padding-bottom: 6px;
    .el-dialog__title{
      color: rgba(255,255,255,0.8);
      font-size: 14px;
      letter-spacing: 3px;
    }
  }
  .el-dialog__body{
    color: rgba(255,255,255,0.9);
    background-color: #0F0F1E;
    font-size: 14px;
    padding: 42px 70px 0 70px;
      .el-input{
        @include input-sty;
        .el-input__inner{
          height: 30px;
          line-height: 30px;
        }
      }
      .warning_content{
        .input_group{
          display: flex;
          align-items: center;
          padding-bottom: 30px;
          .label{
            flex-shrink: 0;
            width: 60px;
            display: inline-block;
            text-align: right;
            margin-right: 10px;
          }
          .el-select{
            width: 100%;
          }
        }
      }
  }
  .el-dialog__footer{
    // padding: 10px 62px 36px 62px;
    background-color: #0F0F1E;
    border-radius: 0px 0px 10px 10px;
    .dialog-footer{
      display: flex;
      justify-content: space-between;
      .el-button{
        border-radius: 20px;
        border: 1px solid #5E5F72;
        background-color:  #2b2a39;
        color: rgba(255,255,255,0.9);
        padding: 6px 15px;
      }
    }
  }
}
.el-dialog.delete_list_dialog{
  .warning_content{
    text-align: center;
    padding-bottom: 16px;
    .warning_icon{
      font-weight: bold;
      margin-right: 9px;
    }
  }
}
.el-dialog.preview_dialog{
    height: auto;
    padding: 13px;
    .el-dialog__header{
      @include text-ellipsis(90%);
    }
    .el-dialog__headerbtn{
      top: 12px;
      .el-dialog__close{
        color: $white8;
      }
    }
    .el-dialog__body{
      margin: 0;
      height: auto;
      overflow: hidden;
      padding: 18px;
      .previewVideo{
        margin-top: 13px;
      }
      .audio_box{
        img{
          width: 100%;
        }
        .xgplayer-backward,.xgplayer-forward,.xgplayer-next,.xgplayer-prev,.xgplayer-name{
          display: none;
        }
        .xgplayer-progress{
          position: absolute;
          top: inherit;
          left: 12px;
          margin-top: -14px;
          .xgplayer-progress-outer{
            height: 3px;
          }
          &:focus,&:hover{
            .xgplayer-progress-outer{
              height: 6px;
              margin-top: 7px;
              .xgplayer-progress-btn{
                top: -3px;
              }
            }
          }
        }
        .xgplayer-placeholder{
          order: 9;
        }
        .xgplayer-play{
          width: 40px;
          height: 50px;
          position: relative;
          order: 0;
          // display: block;
          cursor: pointer;
          margin-left: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          .xgplayer-icon{
            padding: 0;
            margin: 0;
            width: 12px;
            height: 14px;
          }
        }
        .xgplayer-volume{
          order: 10;
          height: 46px;
          .xgplayer-slider{
            left: -6px;
          }
        }
        .xgplayer-time{
          order: 2;
          line-height: 52px;
          height: 50px;
          text-align: center;
          display: inline-block;
          margin: auto 9px;
          top: inherit;
        }
        .xgplayer-skin-default .xgplayer-progress-cache{
          background: rgba(42, 156, 255, 0.6);
        }
        .xgplayer-skin-default .xgplayer-progress-played{
          background-image: linear-gradient(-90deg,#2A9CFF,#2A9CFF);
        }
        .xgplayer-skin-default .xgplayer-drag{
          background:#2A9CFF;
        }
      }
    }
  }
  .el-tooltip__popper{
    z-index: 99999999 !important;
  }
</style>

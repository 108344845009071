<template>
  <!-- 谁可以看 组件 -->
  <div class="input_group">
      <span class="label">谁可以看</span>
      <el-select v-model="viewTypeValue" placeholder="请选择" size="mini" :popper-append-to-body="false">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          @click.native="selectPermission(item)"
          :value="item.value">
        </el-option>
      </el-select>
    <!-- 权限 不给谁看弹窗 -->
      <el-dialog
      :title="viewTypeValue == '3'?'部分可见':'不给谁看'"
      :visible.sync="setPermissionsDialog"
      width="382px"
      top="30vh"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      custom-class="browse-dialog"
      :show-close="true"
      :before-close="downloadClose">
      <div class="search_tree">
          <el-input
            placeholder="输入关键字"
            suffix-icon="el-icon-search"
            size="mini"
            v-model="filterText">
          </el-input>
          <el-tree
            class="filter-tree"
            :data="dataSee"
            show-checkbox
            default-expand-all
            node-key="id"
            ref="tree"
            highlight-current
            @check="checkTree"
            :filter-node-method="filterNode">
          </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="ensurePermission">确 定</el-button>
        <el-button @click="cancelPermission">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      options: [{
          value: '1',
          label: '公开'
        }, {
          value: '2',
          label: '仅自己可见'
        }, {
          value: '3',
          label: '部分可见'
        }, {
          value: '4',
          label: '不给谁看'
      }],
      setPermissionsDialog: false,
      departmentSeeIds:[],
      dataSee: [],
      viewTypeOld:"",
      viewTypeValue: '1',
      filterText: '',
    }
  },
  props: {
    emptydata: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    viewTypeValue(newVal,oldVal) {
      this.viewTypeOld = oldVal
      // console.log(newVal,oldVal)
    },
    emptydata: {
      immediate: true,
      handler: function(val){
        if(val){
          this.departmentSeeIds = []
          this.$refs.tree ? this.$refs.tree.setCheckedKeys([]):'';
          this.viewTypeOld = ""
          this.viewTypeValue = "1"
          this.filterText = ""
          this.$forceUpdate()
        }
      }
    },
  },
  mounted () {
    this.getDepart()
  },
  methods:{
     downloadClose(done) {
      this.setPermissionsDialog = false
    },
    selectPermission (item) {
      // console.log(item)
       this.$emit("typeValue",item.value)
      this.departmentSeeIds = []
      this.$refs.tree ? this.$refs.tree.setCheckedKeys([]):'';
      console.log(item.value)
      if(item.value == '3' || item.value == '4'){
        this.setPermissionsDialog = true
      }
    },
    ensurePermission() {
      this.setPermissionsDialog = false
      this.filterText = ''
    },
    cancelPermission() {
      this.setPermissionsDialog = false
      this.viewTypeValue = this.viewTypeOld //(如果点了弹框的取消的话,还是保留在上一个状态)
      this.filterText = ''
      this.$refs.tree ? this.$refs.tree.setCheckedKeys([]):'';
      // console.log(this.$refs.tree.getCheckedKeys())
      this.$emit("typeValue",this.viewTypeValue)
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },

    checkTree(now,all) {
      this.departmentSeeIds = all.checkedKeys
      all.checkedKeys?all.checkedNodes.forEach((item1,j)=>{
        JSON.stringify(item1.children)?this.departmentSeeIds.splice(j,1):''
      }):''
      this.$emit("departmentSeeIds",this.departmentSeeIds)
    },

    getDepart () {  //部门树接口
        let params = {
          exclude_if: 1
        }
        this.$http({
          method: 'GET',
          url: '/vw_material/get_company_users/',
          params: params
        }).then(res => {
          if (res.data.status === 0) {
              res.data.result.forEach((item,i)=>{
                let obj = {}
                let children = []

                item.users.length ? item.users.forEach((item1,j)=>{
                  let obj1 = {}
                  obj1.id = item1.user_id
                  obj1.label = item1.name
                  children.push(obj1)
                }):""

                obj.children = children
                obj.id = i+1
                obj.label = item.department
                obj.children.length == 0 ? obj.disabled = true:''
                this.dataSee.push(obj)
              })
          } else {
            this.error_msg = res.data.message
          }
        }).catch(error => console.log(error))

    },
  }
}
</script>
<style lang="scss" scoped>
  /deep/.el-select-dropdown{
    margin: 2px 0;
    background: #2D2D3D;
    border: inherit;
    border-radius: 2px;
    .el-scrollbar{
      border-radius: 2px;
    }
    .el-select-dropdown__list{
      padding: 0;
      .el-select-dropdown__item.hover{
        background-color: #3E3D4C;
        color: #409EFF;
        font-weight: 700;
      }
    }
    .popper__arrow{
      display: none;
    }
  }
</style>
<style lang="scss">
@import '~@/assets/css/mixin.scss';
.el-dialog.browse-dialog{
  background-color: #292939;
  border-radius: 15px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
  padding: 8px 21px 21px;
  .el-dialog__header{
    text-align: center;
    padding: 0;
    padding-bottom: 6px;
    .el-dialog__title{
      color: rgba(255,255,255,0.8);
      font-size: 14px;
      letter-spacing: 3px;
    }
    .el-dialog__headerbtn{
      top: 10px;
      .el-dialog__close{
        color: #fff;
        font-size: 18px;
      }
    }
  }
  .el-dialog__body{
    color: rgba(255,255,255,0.9);
    background-color: #0F0F1E;
    font-size: 14px;
    padding: 38px 62px 0 62px;
      .el-input{
        @include input-sty;
      }
      .el-tree{
        background-color: #0F0F1E;
        border: 1px solid #5E5F72;
        margin-top: 15px;
        height: 220px;
        -ms-overflow-style:none;
        @include scrollbar(5px,7px);

        .el-tree-node{
          &:focus>.el-tree-node__content {
            background-color: inherit !important;
          }
          .el-tree-node__content{
            &:hover{
                background-color: inherit !important;
            }
            .el-checkbox{
              width: auto;
            }
            .el-tree-node__label{
              margin-left: 11px;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 70%;
              white-space: nowrap;
              color: rgba(255,255,255,0.8);
            }
            &:hover{
              background-color: #3E3D4C;
              .el-tree-node__label{
                // color: #409EFF;
              }
            }
          }
          &.is-current{
            .el-tree-node__content{
              background-color: inherit;
            }
          }
        }
      }
  }
  .el-dialog__footer{
    padding: 20px 62px 36px 62px;
    background-color: #0F0F1E;
    border-radius: 0px 0px 10px 10px;
    .dialog-footer{
      display: flex;
      justify-content: space-between;
      .el-button{
        border-radius: 20px;
        border: 1px solid #5E5F72;
        background-color: #0F0F1E;
        color: rgba(255,255,255,0.9);
        padding: 6px 15px;
      }
    }
  }
}
</style>

<template>
  <el-container>
    <!-- 侧边栏 -->
    <el-aside width="255px">
      <!-- 侧边栏 logo -->
      <div class="logoContainer">
        <!-- <img class="logo" src="@/assets/images/side-logo.png" alt="" /> -->
        <img class="logo" src="@/assets/images/zhineng_logo.png" alt="" />
      </div>
      <el-divider></el-divider>
      <!-- 上传 -->
      <div class="material_upload_btn">
        <el-button size="small" type="primary" @click="goUpload">
          <img src="@/assets/images/uploading.gif" alt="" v-if="setImgUploading"/>
          <img src="@/assets/images/upload.png" alt="" v-if="!setImgUploading"/>
          <span>上传</span>
        </el-button>
      </div>
      <!-- 侧边菜单栏 -->
      <el-menu
        class="el-menu-vertical-demo"
        text-color="rgba(255, 255, 255, 0.9)"
        active-text-color="rgba(255, 255, 255, 0.9)"
        background-color="#161626"
        router
        :default-active="$route.path"
        @select="clickEvent"
      >
        <!-- 全部素材 -->
        <el-menu-item index="/allrourse">
          <img
            class="upload"
            src="@/assets/images/all-rourse.png"
            alt=""
            style="width: 20px; height: 20px"
          />
          <span slot="title">全部素材</span>
        </el-menu-item>
        <!-- 我的收藏 -->
        <el-menu-item index="/my_collection">
          <img
            class="upload"
            src="@/assets/images/my-store.png"
            alt=""
            style="width: 20px; height: 20px"
          />
          <span slot="title">我的收藏</span>
        </el-menu-item>
        <!-- 我的素材 -->
        <el-menu-item index="/my_material">
          <img
            class="upload"
            src="@/assets/images/my-rourse.png"
            alt=""
            style="width: 20px; height: 20px"
          />
          <span slot="title">我的素材</span>
        </el-menu-item>
        <!-- 素材传输 -->
        <el-menu-item index="/uploading">
          <img
            class="upload"
            src="@/assets/images/rourse-send.png"
            alt=""
            style="width: 20px; height: 20px"
          />
          <span slot="title">上传记录</span>
        </el-menu-item>
        <!-- <el-submenu index="4">
          <template slot="title">
            <img
              class="upload"
              src="@/assets/images/rourse-send.png"
              alt=""
              style="width: 20px; height: 20px"
            />
            <span slot="title">素材传输</span>
          </template>
          <el-menu-item index="/uploading">
            <span slot="title" class="ing">上传</span>
          </el-menu-item>
          <el-menu-item index="/download">
            <span slot="title" class="ing">下载</span>
          </el-menu-item>
          <el-menu-item index="/transferover">
            <span slot="title" class="ing">传输完成</span>
          </el-menu-item>
        </el-submenu> -->
        <!-- 标签管理 -->
        <el-submenu index="5">
          <template slot="title">
            <img
              class="upload"
              src="@/assets/images/label-manager.png"
              alt=""
              style="width: 20px; height: 20px"
            />
            <span slot="title">标签管理</span>
          </template>
          <el-menu-item index="/tag">
            <span slot="title" class="ing">标签</span>
          </el-menu-item>
          <el-menu-item index="/tag_group">
            <span slot="title" class="ing">标签组</span>
          </el-menu-item>
        </el-submenu>
        <!-- 回收站 -->
        <el-menu-item index="/recycle">
          <img
            class="upload"
            src="@/assets/images/waste.png"
            alt=""
            style="width: 20px; height: 20px"
          />
          <span slot="title">回收站</span>
        </el-menu-item>
      </el-menu>
      <StripList></StripList>
    </el-aside>
    <el-container style="height: 100vh;overflow:hidden">
      <!-- 头部 -->
      <el-header height="52px">
        <div class="head">
          <!-- <div style="display: flex;align-items: center;"> -->
            <!-- <img src="../assets/images/zhineng_logo.png" alt="" class="left_logo"> -->
            <div class="title">智能化多媒体管理和生产系统</div>
          <!-- </div> -->
          <div class="user">
            <el-dropdown
              trigger="click"
              placement="bottom-start"
              @command="handleCommand"
            >
              <span class="el-dropdown-link">
                {{ name
                }}<i style="padding-left: 5px" class="el-icon-caret-bottom"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="header_dropdown">
                <el-dropdown-item command="a">个人信息</el-dropdown-item>
                <el-dropdown-item command="b">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-header>
      <!-- 内容区域 -->
      <el-main>
        <router-view v-if="isRouterAlive"></router-view>
        <global-uploader></global-uploader>
        <div v-if="this.$route.path !== '/watchlist'" ><ToWatchList></ToWatchList></div>
      </el-main>

    </el-container>
  </el-container>
</template>

<script>
import globalUploader from '../component/uploadGlobal.vue'
import ToWatchList from '@/component/ToWatchListest.vue' //观看列表
import StripList from '@/component/StripList.vue' //拆条列表

export default {
  name:'home',
  components: {
    globalUploader,
    ToWatchList,
    StripList
  },
  provide () {  //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
      return {
        reload: this.reload
      }
  },
  data() {
    return {
      path: "",
      isRouterAlive: true  //控制视图是否显示的变量
    };
  },
  created () {
    sessionStorage.removeItem('time')
  },
  watch: {
    "$store.state.materialDetail": {
      deep: true,
      handler: function (newValue, oldValue) {
        if (newValue) {
          this.reload()
          this.$store.commit("goMaterialDetail", false);
        }
      }
    }
  },
  mounted () {
  },
  methods: {
    reload () {
      this.isRouterAlive = false;  //先关闭，
      this.$nextTick(function () {
          this.isRouterAlive = true;  //再打开
          if(this.$route.name=='allrourse'){
            this.$route.params.tag_id=undefined
          }
      })
    },
    goUpload() {
      this.$router.push({
        path: "/material_upload",
      });
    },
    //下拉菜单事件
    handleCommand(command) {
      // 个人信息
      if (command == "a") {
        this.$router.push("/editUers");
      }
      // 退出登录
      if (command == "b") {
        this.$http({
          url: "accounts/logout",
          method: "GET",
        }).then((res) => {
          // 成功
          if (res.data.status == 0) {
            localStorage.clear()
            this.$router.push("/login");
          }
        }).catch(error => console.log(error));
      }
    },
    clickEvent(index,path) {
      switch (index) {
        case "/allrourse":  //全部素材
          this.$store.commit("allroursePage", true);
        break;
        case "/my_collection":  //我的收藏
          this.$store.commit("myCollectionPage", true);
        break;
        case "/my_material":  //我的素材
          this.$store.commit("myMaterialPage", true);
        break;
        case "/tag":   //标签
          this.$store.commit("tagPage", true);
        break;
        case "/tag_group":   //标签组
          this.$store.commit("tagGroupPage", true);
        break;
        case "/uploading":  //上传列表
          this.$store.commit("uploadingPage", true);
        break;
        case "/recycle":  //回收站
          this.$store.commit("recyclePage", true);
        break;
      }
    }
  },
  computed:{
    name(){
      return this.$store.state.userInfo.username

    },
    setImgUploading:{
      get() {
        return this.$store.state.imgUploading
      }

    }
  },
};
</script>

<style lang="scss" scoped>
.logoContainer{
  width: 100%;
  text-align: center;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo {
    width: 175px;
}
}
.el-divider--horizontal {
  display: block;
  width: 242px;
  height: 0.5px;
  background: rgba(94, 95, 114, 0.2);;
  margin: 0 auto 0 auto;
}
.material_upload_btn {
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  padding-right: 20px;
  text-align: right;
  margin-top: 31px;
  .el-button {
    border-radius: 7px;
    padding: 5px 13px;
    background-color: #489be4;
    img {
      width: 18px;
      height: 17px;
      margin-right: 3px;
      vertical-align: bottom;
    }
    span {
      vertical-align: bottom;
    }
  }
}
.el-menu-item *,
.el-submenu__title * {
  margin-left: 7px;
}
/deep/.el-submenu__icon-arrow{
color:#ffff
}
/deep/.el-aside {
  height: 100vh;
  background: #161626;
  overflow-x: hidden;
  border-right: 1px solid #000
}
/deep/.el-menu-vertical-demo {
  margin-top: 25px;
  border-right: solid 0px #e6e6e6;
}
/deep/.el-menu .el-menu-item,
/deep/.el-menu .el-submenu__title {
  height: 40px;
  line-height: 40px;
  list-style: none;
  position: relative;
  white-space: nowrap;
  &:hover {
    background-color: rgba(94,95,114,0.2) !important;
  }
}
/deep/.el-menu-item {
  font-size: 14px;
  color: #303133;
  padding: 0 0px;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  box-sizing: border-box;
}
/deep/.el-menu-item:hover {
  background-color: rgba(94,95,114,0.2) !important;
}
.el-menu-item.is-active {
  background-color: rgba(94,95,114,0.2) !important;
  color: #fff;
}
.ing {
  padding-left: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.9);
}
.el-header {
  background: #161626;
  padding: 0;
  border-bottom: 1px solid #000;
  // z-index: 10;
}
.head {
  display: flex;
  justify-content: space-between;
  height: 52px;
  width: 100%;
  align-items: center;
  padding: 0 96px 0 65px;
  box-sizing: border-box;
  .title {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.9);
  }
}
.el-main {
  padding: 0px;
  overflow: hidden;
  height: 100%;
}
.user {
  cursor: pointer;
}
.header_dropdown {
  background-color: #2D2D3D !important;
  margin-top: 19px !important;
  border: none;
}
.el-dropdown-link {
  color: #fff;
}
.popper__arrow {
  background-color: red !important;

}
.el-dropdown-menu__item {
  list-style: none;
  line-height: 33px;
  margin: 0;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  outline: 0;
  width: 56px;
  height: 33px;
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #2D2D3D
;
  color: #ffff;
}
.el-dropdown-menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 0px 0;
  margin: 0px 0;
}
</style>
<style lang="scss">
.el-popper[x-placement^="bottom"] .popper__arrow::after {
  top: 1px;
  margin-left: -6px;
  border-top-width: 0;
  border-bottom-color: #4a4b5b;
}
.el-popper[x-placement^="bottom"] .popper__arrow {
  border-bottom-color: #4a4b5b;
  display:none
}
</style>

<template>
  <div class="watch-container">
    <!-- 加入成功提示按钮 -->
    <transition name="fade">
      <div class="add-success" v-show="this.$store.state.addSuccess">
        加入成功
      </div>
    </transition>
    <div class="watch-list-warp" @click.self="showWatchList">
      <!--当前播放视频 跟 下边观看列表 -->
      <div class="watch-list-abbr" v-show="show">
        <!-- 当前播放视频 -->
        <div class="video-warp" v-show="isPlayVideo">
          <Xgplayer :config="playerConfig" @player="playInit($event)" />
          <!-- 当前播放视频底部信息 -->
          <div class="playing-video">
            <span class="playing-item">
              <!-- 名字 -->
              <p
                style="
                  width: 170px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                "
              >
                {{ currentVideo.name }}
              </p>
              <!-- 时长 -->
              <p>{{ currentVideo.duration }}</p>
            </span>
            <span
              style="cursor: pointer"
              class="show-list-icon show-list-icon-top"
              @click="showList"
              v-if="openList === false"
            ></span>
            <span
              class="show-list-icon show-list-icon-down"
              @click="hideList"
              v-if="openList === true"
            ></span>
          </div>
        </div>
        <!-- 观看列表 -->
        <transition name="list">
          <div :style="{'max-height': isPlayVideo==true? '230px' : '350px'}" class="video-list-wrap" v-show="openList">
            <!-- 每一项 -->
            <dl
              style="cursor: pointer"
              class="list-item"
              v-for="(item, index) in watchListData"
              :key="item.ip_id"
              @click.stop="switchVideo(item, index)"
            >
              <!-- 视频头一帧图片 -->
              <dd class="video-img-warp">
                <!-- 视频头一帧图片 -->
                <img class="gif" :myid="item.ip_id" :src="item.cover_img" />
                <i class="iconfont icon-shipin1" v-if="!item.cover_img"></i>
                <div :class="index === currentIndex ? 'active' : ''">
                  <img
                    class="video-status"
                    v-if="index === currentIndex"
                    src="../assets/images/playing.png"
                    alt=""
                  />
                </div>
              </dd>
              <!-- 视频名字 时长  -->
              <dt>
                <p>{{ item.name }}</p>
                <p>{{ item.duration }}</p>
              </dt>
            </dl>
          </div>
        </transition>
      </div>
      <!-- 视频列表底部按钮 -->
      <div class="watch-list-icon">
        <span
          style="cursor: pointer"
          class="watch-img"
          @click="showWatchList"
        ></span>
        <span v-if="show">观看列表({{ listNum == "" ? 0 : listNum }})</span>
        <span
          style="cursor: pointer"
          v-if="show"
          class="to-watch"
          @click="goToWatch"
        ></span>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '~@/assets/css/mixin.scss';
.watch-list-icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.watch-container {
  position: fixed;
  right: 15px;
  bottom: 0;
  z-index: 100;

  .add-success {
    font-size: 16px;
    position: absolute;
    top: -41px;
    left: 0px;
    color: #fff;
    background: #2a9cff;
    padding: 4px 12px;
    border-radius: 4px;
    margin-left: -48px;
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      background: transparent;
      border-top: 10px solid #2a9cff;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }
  }
}
.watch-list-warp {
  border: 1px solid #2a9cff;
  border-bottom: none;
  padding: 10px;
  border-radius: 6px 6px 0px 0px;
  background: #2b2b3b;
  max-height: 560px;
  cursor:pointer;
  .watch-img {
    width: 16px;
    height: 16px;
    margin: 5px;
    display: inline-block;
    background: url("../assets/images/watch-list.png") no-repeat 0 0;
    background-size: cover;
  }
  .to-watch {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-left: auto;
    background: url("../assets/images/gowatch.png") no-repeat 0 0;
    background-size: cover;
  }
}

.video-warp {
  width: 100%;
  padding-bottom: 10px;
  /deep/.xgplayer-skin-skinName{
    .xgplayer-controls{
      justify-content: flex-end;
    }
    .xgplayer-placeholder{
      display:none
    }
    .xgplayer-play{
      display:none
    }
    .xgplayer-volume{
      width: 30px;
    }
    .xgplayer-slider{
      height: 70px;
    }
    .xgplayer-bar{
      height: 63px;
    }
    .xgplayer-time{
      display:none
    }
    .xgplayer-progress{
      left: 0px;
      right: 0px;
      top: 28px;
    }
    .xgplayer-start{
    border-radius: 0%;
    width: 100%;
    height: 90%;
    left: 17%;
    top: 31%;
    .xgplayer-icon-play{
      width: 100%;
      margin-top: 20px;
    }
    }
  }
}
.watch-list-abbr {
  width: 205px;
  padding-bottom: 10px;
  transition: all 1s ease-in;
  max-height: 532px;

  &.move-enter-active {
    opacity: 1;
  }
  &.move-enter {
    opacity: 0;
  }
  &.move-leave-to {
    opacity: 0;
  }
  .list-item {
    display: flex;
    justify-content: flex-start;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    dd {
      @include common-item(80px,46px);
      min-width: 80px;
      margin-right: 5px;
    }
    dt p:first-child {
      line-height: 18px;
      text-overflow: ellipsis;
      // display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      width: 100px;
      white-space: nowrap;
      overflow: hidden;
    }
    dt p:last-child {
      line-height: 14px;
      text-align: right;
      margin-top: 12px;
      font-size: 12px;
    }
  }
}
.video-list-wrap {
  @include scrollbar(4px,5px);
}

.video-img-warp {
  .video-status {
    position: absolute;
    padding: 2px;
    border-radius: 50%;
    left: 40px;
    top: 23px;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.6);
  }
}
// 加入成功的样式切换
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
// 当前播放视频底部信息
.playing-video {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  // font-size: 14px;
  .playing-item {
    margin-right: 10px;
    cursor: unset;
  }
  //   箭头样式
  .show-list-icon {
    display: inline-block;
    width: 9px;
    height: 8px;
    margin-top: 10px;
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    box-sizing: border-box;
    margin-right: 5px;
    &-top {
      transform: rotate(45deg);
    }
    &-down {
      transform: rotate(-135deg);
    }
  }
}
</style>
<script>
import Xgplayer from "xgplayer-vue";
import Player from "xgplayer";
import '../assets/.xgplayer/skin/index.js'
import { preventSpaceScroll, gifMethods } from "../plugins/solvemethod";
import $ from "jquery";
export default {
  name: "ToWatchList",
  components: {
    Xgplayer,
  },
  data() {
    return {
      show: false, // 观看列表 展示
      addSuccess: false, // 添加成功展示
      openList: false, // 播放列表展示折叠
      isPlayVideo: false, // 点击视频 播放视频 展示
      ConfigParams: {
        // 视频播放器共有
        id: "video",
        lang: "zh-cn",
        fluid: true,
        autoplay: true,
        // download: true,
        ignores: ["fullscreen"],
        src: "",
      },
      playerConfig: {}, // 视频播放器
      currentVideo: {},
      currentIndex: null,
      videoList: [
        {
          // id: 0,
          //   duration: "02:30",
          //   name: "隐秘的角落",
          //   episode: "一",
          //   scene_rank: "15",
          //   scene_num: "30",
          //   poster: require("../assets/images/video.png"),
          //   url: require("../assets/video/video1.mp4"),
        },
      ],
      player: null,
      autoplay: true,
      listNum: "", //视频个数
      watchListData: [], //播放列表数据
    };
  },
  computed: {
    addEffect() {
      return this.$store.state.addSussessfully;
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.openList = true;
      }
    },
    "$store.state.addSuccess": {
      deep: true,
      handler: function (newValue, oldValue) {
        if (newValue == true) {
          // 请求观看列表
          this.getWatchList();
        }
      },
    },
    "$store.state.Delete": {
      deep: true,
      handler: function (newValue, oldValue) {
        if (newValue == true) {
          // 请求观看列表
          this.getWatchList();
        }
      },
    },
    // 监听素材详情页面的视频是否在播放
    "$store.state.playing": {
      deep: true,
      handler: function (newValue, oldValue) {
        if (newValue == 2) {
          if (this.player != null) {
            this.player.pause();
          }
        }
      },
    },
  },

  created() {
    // 请求观看列表
    this.getWatchList();
  },
  methods: {
    // 请求观看列表
    getWatchList() {
      // 请求观看列表
      this.$http({
        url: "vw_material/watch_record_list",
        method: "GET",
      }).then((res) => {
        // console.log("观看列表数据", res.data);
        // 请求成功
        if (res.data.status == 0) {
          this.listNum = res.data.page_num;
          this.watchListData = res.data.result;
        }
        // 请求失败
        else {
        }
      }).catch(error => console.log(error));
    },
    // 关闭播放列表
    showWatchList() {
      this.show = !this.show;
      if (!this.show) {
        this.player && this.player.pause();
      }
    },
    // 去往观看列表
    goToWatch() {
      if (document.querySelector(".xgplayer-time-current") != null) {
        var currentTime = document.querySelector(
          ".xgplayer-time-current"
        ).innerHTML;
      }
      this.$router.push({
        name: "watchlist",
        params: {
          id: this.player != null ? this.player.config.ip_id : null,
          currentTime: currentTime,
        },
      });

    },
    // 初始化视频播放器，并判断结束是是否自动顺序播放
    playInit($event) {
      if (document.getElementById("video") === null) {
        this.switchVideo(this.currentVideo, this.currentIndex);
        this.playerConfig.url = "";
        this.openList = false;
      }
      this.player = $event;
      this.monitorEnded();
      this.monitorPause();
      this.monitorPlaying();
      // this.momitorDownLoad()
    },
    // 点击播放相应视频
    switchVideo(item, index) {
      this.isPlayVideo = true;
      if (this.player && this.currentIndex === index && !item.play_status) {
        this.player.play();
        this.videoList[index].play_status = true;
        return false;
      } else if (
        this.player &&
        this.currentIndex === index &&
        item.play_status
      ) {

        this.player.pause();
        this.videoList[index].play_status = false;
        return false;
      } else {

        this.videoList.forEach((item, i) => {
          item.play_status = i === index;
        });
      }
      this.playVideo(item);
      this.currentIndex = index;
      this.currentVideo = item;
      //   设置下一集数据
      if (this.watchListData.length == 1) {
        let oneItem = {
          duration: this.watchListData[0].duration,
          name: this.watchListData[0].name,
          url: this.watchListData[0].url,
        };
        this.videoList.push(oneItem);
      } else {
        for (let jtem of this.watchListData) {
          if (item.url != jtem.url) {
            let list = {
              duration: jtem.duration,
              name: jtem.name,
              url: jtem.url,
            };
            this.videoList.push(list);
          }
        }
      }
    },
    // 自动切换下一条视频
    switchNextVideo() {
      if (this.player) {
        this.player.destroy();
      }
      var listLength = this.videoList.length;
      var currentIndex = this.currentIndex + 1;
      this.currentIndex = currentIndex === listLength ? 0 : currentIndex;
      this.currentVideo = this.videoList[this.currentIndex];
      this.videoList.forEach((item, index) => {
        item.play_status = this.currentIndex === index;
        if (index === this.currentIndex) {
          this.playerConfig = Object.assign({}, this.ConfigParams, item);
          return false;
        }
      });
      setTimeout(() => {
        this.player.src = this.playerConfig.url;
        //   设置下一集数据
        if (this.watchListData.length == 1) {
          let oneItem = {
            duration: this.watchListData[0].duration,
            name: this.watchListData[0].name,
            url: this.watchListData[0].url,
          };
          this.videoList.push(oneItem);
        } else {
          for (let jtem of this.watchListData) {
            if (item.url != jtem.url) {
              let list = {
                duration: jtem.duration,
                name: jtem.name,
                url: jtem.url,
              };
              this.videoList.push(list);
            }
          }
        }
      }, 0);
    },
    // 播放视频
    playVideo(item) {
      if (this.player) {
        this.player.destroy();
      }
      setTimeout(() => {
        this.playerConfig = Object.assign({}, this.ConfigParams, item);
        this.$nextTick(() => {
          this.player.src = this.playerConfig.url;
        });
      }, 0);
    },
    // 监听视频播放状态
    monitorPlaying() {
      this.player.on("playing", () => {
        this.videoList.forEach((item, index) => {
          if (index === this.currentIndex) {
            item.play_status = true;
            // 通知素材详情播放视频暂停
            this.$store.commit("setplaying", 1);
            setTimeout(() => {
              this.$store.commit("setplaying", 0);
            }, 0);
          }
        });
      });
    },
    // 监听视频暂停状态
    monitorPause() {
      this.player.on("pause", () => {
        this.videoList.forEach((item, index) => {
          if (index === this.currentIndex) {
            item.play_status = false;
          }
        });
      });
    },
    // 监听视频结束状态
    monitorEnded() {
      this.player.on("ended", () => {
        if (this.autoplay) {
          this.switchNextVideo();
        }
      });
    },
    showList() {
      this.openList = true;
    },
    hideList() {
      this.openList = false;
    },
  },
  mounted() {
    preventSpaceScroll($(".video-list-wrap"));
  },
};
</script>

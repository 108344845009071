var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input_group" },
    [
      _c("span", { staticClass: "label" }, [_vm._v("谁可以看")]),
      _c(
        "el-select",
        {
          attrs: {
            placeholder: "请选择",
            size: "mini",
            "popper-append-to-body": false
          },
          model: {
            value: _vm.viewTypeValue,
            callback: function($$v) {
              _vm.viewTypeValue = $$v
            },
            expression: "viewTypeValue"
          }
        },
        _vm._l(_vm.options, function(item) {
          return _c("el-option", {
            key: item.value,
            attrs: { label: item.label, value: item.value },
            nativeOn: {
              click: function($event) {
                return _vm.selectPermission(item)
              }
            }
          })
        }),
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.viewTypeValue == "3" ? "部分可见" : "不给谁看",
            visible: _vm.setPermissionsDialog,
            width: "382px",
            top: "30vh",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "custom-class": "browse-dialog",
            "show-close": true,
            "before-close": _vm.downloadClose
          },
          on: {
            "update:visible": function($event) {
              _vm.setPermissionsDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "search_tree" },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "输入关键字",
                  "suffix-icon": "el-icon-search",
                  size: "mini"
                },
                model: {
                  value: _vm.filterText,
                  callback: function($$v) {
                    _vm.filterText = $$v
                  },
                  expression: "filterText"
                }
              }),
              _c("el-tree", {
                ref: "tree",
                staticClass: "filter-tree",
                attrs: {
                  data: _vm.dataSee,
                  "show-checkbox": "",
                  "default-expand-all": "",
                  "node-key": "id",
                  "highlight-current": "",
                  "filter-node-method": _vm.filterNode
                },
                on: { check: _vm.checkTree }
              })
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.ensurePermission }
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.cancelPermission } }, [
                _vm._v("取 消")
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }